import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ButtonToolbar } from 'react-bootstrap';

export default class RedirectDental extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: [],
            button: false,
            titulos1: '',
            titulos2: '',
            subtitles1: ''
        }
    }

    componentDidMount() {
        var id_produto = this.props.idop
        //console.log(valor.id_produto)
        if(id_produto === '11') {
            this.setState({titulos1: 'Aguarde ...'})
            this.setState({titulos2: 'você sera redirecionado para concluir seu cadastro Amil Dental'})
            this.setState({subtitles1: ''})
            setTimeout(() => {
                let url = this.props.urlProduto

                if(this.props.tipo === '1') {
                    url = 'https://www.amildental.com.br/plano-odontologico/dental-205-para-1-ou-2-pessoas/961911_C12021?corretora=059544&codigoCorretor=61339644304&supervisor.corretor.codigoCorretor=44257139315'
                }

                if(this.props.tipo === '2') {
                    url = 'https://www.amildental.com.br/plano-odontologico/dental-205-empresarial/961922_C12021?corretora=059544&codigoCorretor=61339644304&supervisor.corretor.codigoCorretor=44257139315'
                }

                if(this.props.tipo === '3') {
                    url = 'https://www.amildental.com.br/plano-odontologico/dental-205-para-3-ou-mais/961912_C12021?corretora=059544&codigoCorretor=61339644304&supervisor.corretor.codigoCorretor=44257139315' 
                }

                if(this.props.tipo === '3' && this.props.qPessoas > '2') {
                    url = 'https://www.amildental.com.br/plano-odontologico/dental-205-para-3-ou-mais/961912_C12021?corretora=059544&codigoCorretor=61339644304&supervisor.corretor.codigoCorretor=44257139315' 
                }

                if(this.props.tipo === '3' && this.props.qPessoas < '3') {
                    url = 'https://www.amildental.com.br/plano-odontologico/dental-205-para-1-ou-2-pessoas/961911_C12021?corretora=059544&codigoCorretor=61339644304&supervisor.corretor.codigoCorretor=44257139315' 
                }
                
                window.location.href = url
            }, 3000);
        }

        if(id_produto !== '11') {
            this.setState({titulos1: 'Finalizamos, obrigado!'})
            this.setState({titulos2: 'você sera redirecionado para concluir seu Plano Odontológico'})
            this.setState({subtitles1: 'Nas próximas horas um dos nossos corretores entrará em contato com você. Fique atento!'})
            setTimeout(() => {
                let url = this.props.urlProduto

                if(this.props.tipo === '1') {
                    url = 'https://www.amildental.com.br/plano-odontologico/dental-205-para-1-ou-duas-pessoas/961907_DENTAL?corretora=59544&codigoCorretor=61339644304&utm_source=comunicaamil&utm_medium=link'
                }
                if(this.props.tipo === '2') {
                    url = 'https://www.amildental.com.br/plano-odontologico/dental-205-empresarial/961919_DENTAL?corretora=59544&codigoCorretor=61339644304&utm_source=comunicaamil&utm_medium=link'
                }
                if(this.props.tipo === '3') {
                    url = 'https://www.amildental.com.br/plano-odontologico/dental-205-para-1-ou-duas-pessoas/961907_DENTAL?corretora=59544&codigoCorretor=61339644304&utm_source=comunicaamil&utm_medium=link' 
                }
                if(this.props.tipo === '3' && this.props.qPessoas > '3') {
                    url = 'https://www.amildental.com.br/plano-odontologico/dental-205-para-3-ou-mais/961908_DENTAL?corretora=59544&codigoCorretor=61339644304&utm_source=comunicaamil&utm_medium=link' 
                }
                window.location.href = url
            }, 3000);
        }
    }

    render() {
        return (
            <Col className="obrigado">
                <h1 className="text-center">{this.state.titulos1}</h1>
                <h2 style={{fontSize: 23 }}>{this.state.titulos2}</h2>
                <p>{this.state.subtitles1}</p>
                <ButtonToolbar className="mt-3" style={{justifyContent:'space-around'}}>
                    <CircularProgress size={32} className="my-auto mx-2" />
                </ButtonToolbar>
            </Col>
        );
    }
}
