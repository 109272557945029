import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { Button, ButtonToolbar } from 'react-bootstrap';
import api from '../../src/services/api'
import CircularProgress from '@material-ui/core/CircularProgress';
//import { setHours } from 'date-fns';

export default class Pdental extends Component {

    constructor(props) {
        super(props)
        this.state = {
            error: [],
            button: false,
            valor: 0,
            valorPlano: '',
            estoqueLeadsDental: 0,
        }
    }

    async getEstoque() {
        try {
            const apiUrl = 'https://app.leadmark.com.br/integracao/quantidade';
            const response = await fetch(apiUrl);
            const responseToJson = await response.json()
            this.setState({ estoqueLeadsDental: responseToJson[1].qtde})
        } catch (error) {
            console.log(error)
        }
    }

    async componentDidMount() {
        await this.getEstoque()
        const formData = localStorage.getItem('leadDental')
        const valor = JSON.parse(formData)
        //console.log(valor.id_produto)
        if(valor?.id_produto === '11') {
            this.props.nextStep() 
        }

        if(valor.id_tipo !== '1') {
            this.setState({valorPlano: '37,20'})
        }

        if(valor.id_tipo === '2') {
            this.setState({valorPlano: '24,30'})
        }

        if(valor.id_tipo === '3') {
            this.setState({valorPlano: '37,20'})
        }

        if(valor.id_tipo === '3' && valor.vidas > '2') {
            this.setState({valorPlano: '27,20'})
        }
    }


    async UpgradeLead() {
        const formData = localStorage.getItem('leadDental')
        const valor = JSON.parse(formData)
        valor.id_segmento = 2
        valor.id_origem = 6
        valor.id_produto = 11
        valor.cadastro_finalizado = 1
        localStorage.setItem('leadDental', JSON.stringify(valor))
        const dados = localStorage.getItem('leadDental')

        const jsonToSend = JSON.parse(dados)
        //console.log(jsonToSend)

        let response = await api.post('integracao/cadastro', jsonToSend)
        //console.log(response);
        if (response.data.status === 'error') {
            this.setState({ error: response.data.critica })
            this.setState({ button: !this.state.button })
            this.props.nextStep()

        }
        if (response.data.status === "success") {
            localStorage.setItem('idDental', response.data.lead.dados.id)
            this.props.nextStep()
            
        }
    }

    async UpgradeLeadPlanodeSaude() {
        localStorage.removeItem('idDental')
        localStorage.removeItem('leadDental')
        const idLead = localStorage.getItem('idLead')
        console.log('------ ID LEAD SAUDE ------');
        console.log(idLead);
        console.log('---------------------------');

        const data = {
            id_origem: this.props.idorigem,
            id: parseInt(idLead),
            id_produto: this.props.idop,
            cadastro_finalizado: 1,
            id_segmento: 1
        }

        try {
            let response = await api.post('integracao/cadastro', data)
            console.log(response);
        } catch (err) {
            console.error(err)
        }
    }

    handleChangePdental(event) {
        this.props.handPdental(event)
        this.setState({valor: event})
        //console.log(event)
    }
    continue = e => {
        e.preventDefault();
        // if (this.state.valor > 0) {
        //     this.setState({ button: !this.state.button })
        //     const idop = this.props.idop;
        //     const estoque = this.state.estoqueLeadsDental;

        //     // const currentHour = setHours(new Date(), 0).getHours();
        //     const currentHour = new Date().getHours()
        //     const minHour = 7;
        //     const maxHour = 22;
    
        //     if (currentHour >= minHour && currentHour < maxHour) {
        //         //console.log('pode')
        //         if(idop === '11' && estoque > '200') {
        //             console.log('plano familiar')
        //             this.UpgradeLeadPlanodeSaude();
        //             this.props.nextStep(16)
        //         }
        //         if(idop === '11' && estoque < '200'){
        //             this.setState({ error: [] })
        //             this.setState({ button: !this.state.button })
        //             this.UpgradeLead()
        //         }
        //         if(idop !== '11' && estoque >= '200'){
        //             this.setState({ error: [] })
        //             this.setState({ button: !this.state.button })
        //             this.UpgradeLeadPlanodeSaude();
        //             this.props.nextStep(16)
        //         }
        //         if(idop !== '11' && estoque < '200'){
        //             this.setState({ error: [] })
        //             this.setState({ button: !this.state.button })
        //             this.UpgradeLead()
        //         }
        //     } else {
        //         //console.log('não pode')
        //         return this.props.nextStep(16)
        //     }

        //     // const currentDate = setHours(new Date(), 2);
        //     // const minHour = setMinutes(setHours(new Date(), 7), 0);
        //     // const maxHour = setMinutes(setHours(new Date(), 21), 59);
            
        //     // console.log(currentDate)
        //     // console.log(minHour)
        //     // console.log(maxHour)

        //     // console.log(!isAfter(currentDate, maxHour) && isBefore(currentDate, minHour))
    
        //     // if (!isAfter(currentDate, maxHour) && isBefore(currentDate, minHour)) {
        //     //     //return console.log('Não pode');
        //     //     return this.props.nextStep(16)
        //     // }else{
                
        //     // }
            

        // }else {
        //     //localStorage.removeItem('leadDental')
        //     this.props.nextStep() 
        // }
        
        // if (this.props.pdental.length === 0) {
        //     this.props.nextStep()
        // } else {
        //     this.setState({ button: !this.state.button })
        //     const idop = this.props.idop;
        //     const estoque = this.state?.estoqueLeadsDental;
        //     if(idop !== '11' && estoque !== undefined){
        //         this.setState({ error: [] })
        //         this.setState({ button: !this.state.button })
        //         this.UpgradeLeadPlanodeSaude();
        //         this.props.nextStep(16)
        //     }
        // }

        if (this.state.valor > 0) {
            this.setState({ button: !this.state.button })
            const idop = this.props.idop;
            const estoque = this.state?.estoqueLeadsDental;
            if(idop !== '11' && estoque !== undefined){
                this.setState({ error: [] })
                this.setState({ button: !this.state.button })
                this.UpgradeLeadPlanodeSaude();
                this.props.nextStep(16)
            }
        } else {
            this.props.nextStep() 
        }

    };
    voltar = e => {
        e.preventDefault();
        this.props.prevStep();
        this.setState({ button: !this.state.button })
    };

    render() {
        return (
            <>
                <h5 className="animated fadeIn mt-3 text-center">Que tal adicionar um Plano Odontológico?</h5>
                <h5 className="animated fadeIn text-center mb-3" style={{fontSize: 16,}}>Por apenas R$ <strong style={{fontSize: 23,}}>{this.state.valorPlano}</strong> (por pessoa/mês)</h5>
                <img src="/promoamil.png" alt="promo amil" width="100%"/>
                
                <Row>
                    <Col md={1} xs={1} className="my-auto text-center text-md-right px-md-0">
                    </Col>
                    <Col md={11} xs={11}>
                        <Form.Check
                            custom
                            type='radio'
                            name='reeenbolso'
                            id="comreeenbolso"
                            label="Sim"
                            value={1}
                            onChange={(e) => this.handleChangePdental(e.target.value)}
                            className="my-3"
                        />
                    </Col>
                    <Col md={1} xs={1} className="my-auto text-center text-md-right px-md-0">
                    </Col>
                    <Col md={11} xs={11}>
                        <Form.Check
                            custom
                            type='radio'
                            name='reeenbolso'
                            id="semreeenbolso"
                            label="Não"
                            value={0}
                            onChange={(e) => this.handleChangePdental(e.target.value)}
                            className="my-3"

                        />
                    </Col>

                </Row>
                <Col md={1} xs={1} className="text-md-right mt-3 mb-4 px-md-0 col-md-10 col-12">
                {/* <h6 className="text-center">até <strong>20% de desconto</strong> e carência <strong>24H</strong> no cartão</h6> */}
                <span>Você receberá o contato de um <strong>especialista em planos Odontológicos</strong> para tirar suas dúvidas.</span>
                </Col>
                
                <ButtonToolbar className="mt-3" style={{justifyContent:'space-around'}}>
                    <Button
                        size="lg"
                        variant="outline-default"
                        onClick={this.voltar}
                    >Voltar
                            </Button>
                    <Button
                        size="lg"
                        variant="outline-primary"
                        onClick={this.continue}
                        disabled={this.state.button}
                    >Continuar
                            </Button>
                            {this.state.button ? <CircularProgress size={24} className="my-auto mx-2" /> : null}
                </ButtonToolbar>
            </>
        )
    }
}
