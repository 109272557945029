import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import { Button, ButtonToolbar } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert'
import api from '../../src/services/api'
import CircularProgress from '@material-ui/core/CircularProgress';
//import { setHours } from 'date-fns';

export default class FamiliarEmpresarial extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: [],
            familiar: false,
            empresarial: false,
            button: false,
            id_tipo: '',
            estoqueLeadsDental: 0
        }
    }

    async getEstoque() {
        try {
            const apiUrl = 'https://app.leadmark.com.br/integracao/quantidade';
            const response = await fetch(apiUrl);
            const responseToJson = await response.json()
            this.setState({ estoqueLeadsDental: responseToJson[1].qtde})
        } catch (error) {
            console.log(error)
        }
    }

    async componentDidMount() {
        await this.getEstoque()
    }

    async UpgradeLead() {
        const idLead = localStorage.getItem('idLead')

        const data = {
            id_origem: this.props.idorigem,

            id: parseInt(idLead),
            id_tipo: this.props.tipo
        }

        try {
            let response = await api.post('integracao/cadastro', data)
            //console.log(response);
            if (response.data.status === 'error') {
                this.setState({ error: response.data.critica })
                this.setState({ button: !this.state.button })
            }
            if (response.data.status === "success") {
                this.props.nextStep()
            }
        } catch (err) {
            console.error(err)
        }
    }

    async UpdateLeadSaudeDental() {
        
        const formData = localStorage.getItem('LeadSaudeDental')
        const data = JSON.parse(formData)
        data.id_origem = this.props.idorigem
        data.id_tipo = this.props.tipo
        
        localStorage.setItem('LeadSaudeDental', JSON.stringify(data))

        try {
            let response = await api.post('integracao/cadastro', data)
            //console.log(response);
            if (response.data.status === 'error') {
                this.setState({ error: response.data.critica })
                this.setState({ button: !this.state.button })
                setTimeout(() => { this.props.nextStep(3) }, 4000);
            }
            if (response.data.status === "success") {
                localStorage.setItem('IdLeadSaudeDental', response.data.lead.dados.id)
                this.props.nextStep()
            }
        } catch (err) {
            console.error(err)
        }
    }

    handleChangeTipoPlano(event) {
        //if(this.props.tipo === '')
        this.props.handFamiliarEmpresarial(event)
        this.setState({ error: [] })
        const formData = localStorage.getItem('leadDental')
        const data = JSON.parse(formData)
        data.id_tipo = event
        localStorage.setItem('leadDental', JSON.stringify(data))
        const tipo = event
        this.setState({id_tipo: tipo})
    }

    continue = e => {
        e.preventDefault();


        if (this.props.tipo.length === 0) {
            this.setState({ error: 'Informe qual tipo de plano você deseja fazer!' })

        } else {
            this.setState({ error: [] })
            this.setState({ button: !this.state.button })

            if(this.props.tipo === '3') {
                window.gtag('event', 'conversion', {'send_to': 'AW-949196845/kjg7CKqfkPcBEK2wzsQD'});
            }
 
             if(this.props.tipo === '2') {
                window.gtag('event', 'conversion', {'send_to': 'AW-949196845/cg6pCKXKp_cBEK2wzsQD'});
            }

            const idop = this.props.idop

            if(idop !== '11') {
                this.setState({ error: [] })
                this.setState({ button: !this.state.button })
                this.UpgradeLead()
            }

            if(idop === '11') {
                this.setState({ error: [] })
                this.setState({ button: !this.state.button })
                this.UpdateLeadSaudeDental();
            }

            // const idop = this.props.idop;

            // const currentHour = setHours(new Date(), 0).getHours();
            // const currentHour = new Date().getHours();
            // const minHour = 7;
            // const maxHour = 22;
    
            // if (currentHour >= minHour && currentHour < maxHour) {
            //     //console.log('pode')
            //     if(this.state.id_tipo === '3' && idop === '11' && this.state.estoqueLeadsDental < '200') {
            //         console.log('plano familiar')
            //         this.UpdateLeadSaudeDental();
            //     }
    
            //     if(this.state.id_tipo === '2' && idop === '11' && this.state.estoqueLeadsDental < '200') {
            //         console.log('plano EMPRESARIAL')
            //         this.UpdateLeadSaudeDental();
            //         //this.props.nextStep(16)
            //     }
            //     if(this.state.id_tipo === '3' && idop === '11' && this.state.estoqueLeadsDental > '200') {
            //         console.log('plano familiar')
            //         //this.UpdateLeadSaudeDental();
            //         this.props.nextStep(16)
            //     }
    
            //     if(this.state.id_tipo === '2' && idop === '11' && this.state.estoqueLeadsDental > '200') {
            //         console.log('plano EMPRESARIAL')
            //         //this.UpdateLeadSaudeDental();
            //         this.props.nextStep(16)
                    
            //     }

            //     if(idop !== '11' && this.state.estoqueLeadsDental < '200') {
            //     this.setState({ error: [] })
            //     this.setState({ button: !this.state.button })
            //     this.UpgradeLead()
            //     }

            // } else {
            //     //console.log('não pode')
            //     return this.props.nextStep(16)
            // }
        }

    };
    voltar = e => {
        e.preventDefault();
        this.props.prevStep();
    };
    render() {

        return (
            <>
                {(() => {
                    if (this.state.error.length === 0) {
                        return
                    } else {
                        return (
                            <div>
                                <Alert variant="danger" style={{ marginTop: 10 }} dismissible>
                                    <Alert.Heading>Ops</Alert.Heading>
                                    <p>{this.state.error}</p>
                                </Alert>
                            </div>
                        )
                    }
                })()}
                <h5 className="text-center my-5 animated fadeIn">Mais de uma pessoa, certo? Qual modalidade de plano deseja contratar?</h5>
                <div className="form-check px-0">
                    <Form.Check
                        custom
                        type='radio'
                        name='tipodeplano'
                        id='familiar'
                        label='Familiar'
                        value='3'
                        onChange={(e) => this.handleChangeTipoPlano(e.target.value)}
                        className="my-3"
                    //checked={this.state.familiar}
                    />
                    <Form.Check
                        custom
                        type='radio'
                        name='tipodeplano'
                        id='empresarial'
                        label={`Empresarial`}
                        value='2'
                        onChange={(e) => this.handleChangeTipoPlano(e.target.value)}
                        className="my-3"
                    //checked={this.state.empresarial}
                    />
                    <span style={{ color: 'red' }}>Planos empresariais com <strong>economia de até 40%.</strong></span>
                </div>
                <ButtonToolbar className="mt-3">
                    <Button
                        size="lg"
                        variant="outline-default"
                        onClick={this.voltar}
                    >Voltar
                            </Button>
                    <Button
                        size="lg"
                        variant="outline-primary"
                        onClick={this.continue}
                        disabled={this.state.button}
                    >Continuar
                            </Button>
                    {this.state.button ? <CircularProgress size={24} className="my-auto mx-2" /> : null}
                </ButtonToolbar>
            </>
        )
    }
}

