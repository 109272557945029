import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert'

export class Desejo extends Component {


    constructor(props) {
        super(props)
        this.state = {
            error: []
        }

    }



    handleChangeDesejo(event) {
        if (event === '1') {
            this.setState({ error: [] })
        }
        this.props.handDesejo(event)
        localStorage.removeItem('leadDental')
        localStorage.removeItem('idDental')
        localStorage.removeItem('idLead')
    }

    continue = e => {
        e.preventDefault();

        if (this.props.desejo.length === 0) {
            this.setState({ error: '0' })
        }
        if (this.props.desejo.length > 0) {
            if (this.props.desejo === '1') {
                this.props.nextStep();
            }
            if (this.props.desejo === '0') {
                this.setState({ error: '1' })
            }
        }
    };
    voltar = e => {
        e.preventDefault();
        this.props.prevStep();
    };
    render() {
        const numbers = [
            { a: 1, nome: 'Contratação de planos' },
            { a: 1, nome: 'Simulação de planos' },
            { a: 1, nome: 'Cotação online de planos' },
            { a: 0, nome: 'Marcação de consultas' },
            { a: 0, nome: 'Boleto' },
            { a: 0, nome: 'Resultado de exame' },
            { a: 0, nome: 'Reclamação' },

        ];
        const listItems = numbers.map((item, i) =>
            <Form.Check
                custom
                type='radio'
                name='desejo'
                key={i.toString()}
                id={`${item.nome}`}
                label={`${item.nome}`}
                value={`${item.a}`}
                onChange={(e) => this.handleChangeDesejo(e.target.value)}
                className='mb-4'
            />
        );
        return (
            <div>
                <Row>
                    <Col md={12} className="mx-auto text-left mt-2">
                        {(() => {
                            if (this.state.error === '1') {
                                return (
                                    <div>
                                        <Alert variant="danger">
                                            <Alert.Heading>Ops...</Alert.Heading>
                                            <p>Somos uma central exclusiva de venda de planos de saúde.</p>
                                        </Alert>
                                    </div>
                                )
                            } else if (this.state.error === '0') {
                                return (
                                    <div>
                                        <Alert variant="danger">
                                            <Alert.Heading>Por favor selecione o que você deseja!</Alert.Heading>
                                        </Alert>
                                    </div>
                                )
                            } else {
                                return
                            }
                        })()}
                        <h5 className="text-center my-5 animated fadeIn">O que deseja?</h5>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="px-4 px-md-0 text-left animated fadeInLeft">
                        {listItems}
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="text-center animated fadeInLeft mx-auto">
                        <Button
                            size="lg"
                            variant="outline-primary"
                            onClick={this.continue}
                        >Próximo <i className="fas fa-arrow-right"></i>
                        </Button>
                    </Col>
                </Row>
            </div>);
    }
}

export default Desejo


