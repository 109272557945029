import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import TextField from '@material-ui/core/TextField';
import { Button, ButtonToolbar } from 'react-bootstrap';
import api from '../../src/services/api'
import CircularProgress from '@material-ui/core/CircularProgress';
// import { isAfter, isBefore, setHours, setMinutes } from 'date-fns';



export default class Idade extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: [],
            button: false,
        }
    }

    async getEstoque() {
        try {
            const apiUrl = 'https://app.leadmark.com.br/integracao/quantidade';
            const response = await fetch(apiUrl);
            const responseToJson = await response.json()
            this.setState({ estoqueLeadsDental: responseToJson[1].qtde})
        } catch (error) {
            console.log(error)
        }
    }

    async componentDidMount() {
        await this.getEstoque()

        // const currentDate = setHours(new Date(), 9);
        // const minHour = setMinutes(setHours(new Date(), 7), 0);
        // const maxHour = setMinutes(setHours(new Date(), 21), 59);

        // if (!isAfter(currentDate, maxHour) && isBefore(currentDate, minHour)) {
        //     return console.log('Não pode');
        // }

        // console.log('Pode');
    }

    async UpgradeLead() {
        const idLead = localStorage.getItem('idLead')

        const data = {
            id_origem: this.props.idorigem,
            id: parseInt(idLead),
            idade: this.props.idade
        }

        try {
            let response = await api.post('integracao/cadastro', data)
            //console.log(response);
            if (response.data.status === 'error') {
                this.setState({ error: response.data.critica })
                this.setState({ button: !this.state.button })

            }
            if (response.data.status === "success") {
                this.props.nextStep()
            }
        } catch (err) {
            console.error(err)
        }
    }

    async UpdateLeadSaudeDental() {
        
        const formData = localStorage.getItem('LeadSaudeDental')
        const data = JSON.parse(formData)
        data.id_origem = this.props.idorigem
        data.idade = this.props.idade
        localStorage.setItem('LeadSaudeDental', JSON.stringify(data))

        try {
            let response = await api.post('integracao/cadastro', data)
            //console.log(response);
            if (response.data.status === 'error') {
                this.setState({ error: response.data.critica })
                this.setState({ button: !this.state.button })
                setTimeout(() => { this.props.nextStep(3) }, 4000);
            }
            if (response.data.status === "success") {
                localStorage.setItem('IdLeadSaudeDental', response.data.lead.dados.id)
                this.props.nextStep()
            }
        } catch (err) {
            console.error(err)
        }
    }


    handleChangeIdade(event) {
        let idade = event.replace(/\D/g, '');
        if (idade === '0')
            this.setState({ error: 'coloque uma idade valida!' })
        else {
            this.props.handIdade(idade)
            this.setState({ error: [] })
            const formData = localStorage.getItem('leadDental')
            const data = JSON.parse(formData)
            data.idade = event
            localStorage.setItem('leadDental', JSON.stringify(data))
            localStorage.setItem('leadDental', JSON.stringify(data))
        }
    }
    continue = e => {
        e.preventDefault();
        if (this.props.idade.length !== 0) {
            const idop = this.props.idop;
            if(idop !== '11'){
                this.setState({ error: [] })
                this.setState({ button: !this.state.button })
                this.UpgradeLead()
            }

            if(idop === '11' ){
                this.setState({ error: [] })
                this.setState({ button: !this.state.button })
                this.UpdateLeadSaudeDental();
            }
        
        }
        else {
            this.setState({ error: 'coloque uma idade valida!' })
        }

    };
    voltar = e => {
        e.preventDefault();
        this.props.prevStep();
    };
    render(props) {
        return (
            <Col md={12} className="px-0 mt-4">
                <h5 className="text-center my-5 animated fadeIn">A seguir, nos informe sua idade.</h5>
                <TextField
                    id="outlined-full-width"
                    type='tel'
                    name="idade"
                    value={this.props.idade}
                    label="Digite aqui...                    "
                    placeholder="Por favor informe sua idade?"
                    onChange={(e) => this.handleChangeIdade(e.target.value)}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                />
                <small style={{ color: 'red' }}>{this.state.error}</small>
                <ButtonToolbar className="mt-3">
                    <Button
                        size="lg"
                        variant="outline-default"
                        onClick={this.voltar}
                    >Voltar
                            </Button>
                    <Button
                        size="lg"
                        variant="outline-primary"
                        onClick={this.continue}
                        disabled={this.state.button}
                    
                    >Continuar
                            </Button>
                            {this.state.button ? <CircularProgress size={24} className="my-auto mx-2" /> : null}
                </ButtonToolbar>
            </Col>
        )

    }
}

