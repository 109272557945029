import React, { Component } from 'react';
import { Button, ButtonToolbar } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';



export default class Nome extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: [],
        }
    }
    handleChangeName(event) {
        if (event.match("^[a-zA-Z ]*$") != null) {
            this.props.handP(event)

            // const data = {
            //     nome: event
            // }

            // localStorage.setItem('leadDental', JSON.stringify(data))
        }
        if (this.props.nome === '') {
            this.setState({ error: [] })
        }
    }

    continue = e => {
        e.preventDefault();
        if (this.props.nome.length > 0) {
            this.setState({ error: [] })
            this.props.nextStep();
        }
        else {
            this.setState({ error: 'coloque um nome valido!' })
        }

    };
    voltar = e => {
        e.preventDefault();
        this.props.prevStep();
    };
    render() {
        return (
            <>
                <h5 className="text-center my-5 animated fadeIn">Vamos começar? Primeiro, nos informe seu nome.</h5>
                {this.state.error.length > 0 ? <TextField
                    label="Digite aqui..."
                    variant="outlined"
                    name="nome"
                    id='nome'
                    value={this.props.nome}
                    onChange={(e) => this.handleChangeName(e.target.value)}
                    helperText={this.state.error}
                    fullWidth
                    error
                /> : <TextField
                        label="Digite aqui..."
                        variant="outlined"
                        name="nome"
                        id='nome'
                        value={this.props.nome}
                        onChange={(e) => this.handleChangeName(e.target.value)}
                        helperText={this.state.error}
                        fullWidth

                    />}
                <ButtonToolbar className="mt-3">
                    <Button
                        size="lg"
                        variant="outline-default"
                        onClick={this.voltar}
                    >Voltar
                            </Button>
                    <Button
                        size="lg"
                        variant="outline-primary"
                        onClick={this.continue}
                    >Continuar
                            </Button>
                </ButtonToolbar>
            </>);
    }
}
