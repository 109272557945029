import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { Button, ButtonToolbar } from 'react-bootstrap';
import Info from './subcomponentes/Info'
import api from '../../src/services/api'
import CircularProgress from '@material-ui/core/CircularProgress';

export default class Reenbolso extends Component {

    constructor(props) {
        super(props)
        this.state = {
            error: [],
            button: false,
        }
    }
    async UpgradeLead() {
        const idLead = localStorage.getItem('idLead')

        const data = {
            id_origem: 6,
            id: parseInt(idLead),
            reembolso: this.props.reenbolso
        }

        try {
            let response = await api.post('integracao/cadastro', data)
            //console.log(response);
            if (response.data.status === 'error') {
                this.setState({ error: response.data.critica })
                this.setState({ button: !this.state.button })

            }
            if (response.data.status === "success") {
                this.props.nextStep()
            }
        } catch (err) {
            console.error(err)
        }
    }

    async UpdateLeadSaudeDental() {
        const idLead = localStorage.getItem('IdLeadSaudeDental')

        const data = {
            id_origem: 6,
            id: parseInt(idLead),
            reembolso: this.props.reenbolso
        }

        try {
            let response = await api.post('integracao/cadastro', data)
            //console.log(response);
            if (response.data.status === 'error') {
                this.setState({ error: response.data.critica })
                this.setState({ button: !this.state.button })

            }
            if (response.data.status === "success") {
                this.props.nextStep()
            }
        } catch (err) {
            console.error(err)
        }
    }

    handleChangeReenbolso(event) {
        this.props.handReenbolso(event)
        const formData = localStorage.getItem('leadDental')
        const data = JSON.parse(formData)
        data.reembolso = event
        localStorage.setItem('leadDental', JSON.stringify(data))
    }
    continue = e => {
        e.preventDefault();
        if (this.props.reenbolso.length === 0) {
            this.props.nextStep()
        } else {
            this.setState({ button: !this.state.button })
            const idop = this.props.idop;
            if(idop !== '11'){
                this.setState({ error: [] })
                this.setState({ button: !this.state.button })
                this.UpgradeLead()
            }

            if(idop === '11'){
                this.setState({ error: [] })
                this.setState({ button: !this.state.button })
                this.UpdateLeadSaudeDental();
            }
        }
    };
    voltar = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    render() {
        return (
            <>
                <h5 className="text-center my-5 animated fadeIn">Optar por um plano com reembolso?</h5>
                <Row>
                    <Col md={1} xs={1} className="my-auto text-center text-md-right px-md-0">
                        <Info title="Com reembolso" msg="Plano com reembolso" />
                    </Col>
                    <Col md={11} xs={11}>
                        <Form.Check
                            custom
                            type='radio'
                            name='reeenbolso'
                            id="comreeenbolso"
                            label="Sim"
                            value="sim"
                            onChange={(e) => this.handleChangeReenbolso(e.target.value)}
                            className="my-3"
                        />
                    </Col>
                    <Col md={1} xs={1} className="my-auto text-center text-md-right px-md-0">
                        <Info title="Sem reembolso" msg="Plano Sem reembolso" />
                    </Col>
                    <Col md={11} xs={11}>
                        <Form.Check
                            custom
                            type='radio'
                            name='reeenbolso'
                            id="semreeenbolso"
                            label="Não"
                            value="nao"
                            onChange={(e) => this.handleChangeReenbolso(e.target.value)}
                            className="my-3"

                        />
                    </Col>
                </Row>
                <ButtonToolbar className="mt-3">
                    <Button
                        size="lg"
                        variant="outline-default"
                        onClick={this.voltar}
                    >Voltar
                            </Button>
                    <Button
                        size="lg"
                        variant="outline-primary"
                        onClick={this.continue}
                        disabled={this.state.button}
                    >Continuar
                            </Button>
                            {this.state.button ? <CircularProgress size={24} className="my-auto mx-2" /> : null}
                </ButtonToolbar>
            </>
        )
    }
}
