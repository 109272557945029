import React, { Component } from 'react';
import { Button, ButtonToolbar } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import { Helmet } from "react-helmet";
import api from '../../src/services/api'
import CircularProgress from '@material-ui/core/CircularProgress';



export class Qpessoas extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: [],
            button: false,
        }
    }

    async UpgradeLead() {
        const idLead = localStorage.getItem('idLead')

        const vidas = this.props.qPessoas
        const data = {
            id_origem: this.props.idorigem,
            id: parseInt(idLead),
            vidas: parseInt(vidas),
        }

        try {
            let response = await api.post('integracao/cadastro', data)
            //console.log(response);
            if (response.data.status === 'error') {
                this.setState({ error: response.data.critica })
                this.setState({ button: !this.state.button })
            }
            if (response.data.status === "success") {
                this.props.nextStep()
            }
        } catch (err) {
            console.error(err)
        }
    }

    async UpdateLeadSaudeDental() {
        
        const formData = localStorage.getItem('LeadSaudeDental')
        const data = JSON.parse(formData)
        data.id_origem = this.props.idorigem
        data.vidas = this.props.qPessoas
        localStorage.setItem('LeadSaudeDental', JSON.stringify(data))
        localStorage.setItem('leadDental', JSON.stringify(data))

        this.props.nextStep()
    }


    handleChangeQpessoas(event) {
        const e = event.replace(/[^\d]+/g, '')
        if (e.length > 3 || e === '0') {
            this.props.handQpessoas('')
            this.setState({ error: 'Ops coloque um numero válido de 1 á 999!' })
        }
        else {
            this.props.handQpessoas(e)
            this.setState({ error: [] })
            const formData = localStorage.getItem('leadDental')
            const data = JSON.parse(formData)
            data.vidas = parseInt(event)
            localStorage.setItem('leadDental', JSON.stringify(data))
        }

    }
    continue = e => {
        e.preventDefault();
        if (this.props.qPessoas.length > 0) {
            this.setState({ error: [] })
            this.setState({ button: !this.state.button })
            const idop = this.props.idop;

            if(this.props.qPessoas === '1') {
                window.gtag('event', 'conversion', {'send_to': 'AW-949196845/QphlCNrZjvcBEK2wzsQD'});
            }

            if(idop !== '11') {
                this.UpgradeLead()
            }

            if(idop === '11') {
                this.UpdateLeadSaudeDental()
            }

            // if(this.props.qPessoas > '1') {
            //     this.UpgradeLead()
            // }
        }

        if (this.props.qPessoas.length === 0) {
            this.setState({ error: 'Informe a quantidade de pessoas!' })
        }
    };
    voltar = e => {
        e.preventDefault();
        this.props.prevStep();
    };
    render() {
        return (<>
        <Helmet>

<script>

   gtag_report_conversion();

</script></Helmet>
            <h5 className="text-center my-5 animated fadeIn">Nos diga, quantas pessoas utilizarão o plano?</h5>
            {this.state.error.length > 0 ? <TextField
                label="Para quantas pessoas é o plano?"
                type='tel'
                variant="outlined"
                name="qpessoas"
                id='qpessoas'
                value={this.props.qPessoas}
                onChange={(e) => this.handleChangeQpessoas(e.target.value)}
                fullWidth
                error
                helperText={this.state.error}
            /> : <TextField
                    label="Digite aqui o número de pessoas"
                    type='tel'
                    variant="outlined"
                    name="qpessoas"
                    id='qpessoas'
                    value={this.props.qPessoas}
                    onChange={(e) => this.handleChangeQpessoas(e.target.value)}
                    fullWidth
                />}
            <ButtonToolbar className="mt-3">
                {/* <Button
                    size="lg"
                    variant="outline-default"
                    onClick={this.voltar}
                >Voltar
                            </Button> */}
                <Button
                    size="lg"
                    variant="outline-primary"
                    onClick={this.continue}
                    disabled={this.state.button}
                >Próximo <i className="fas fa-arrow-right"></i></Button>
                {this.state.button ? <CircularProgress size={24} className="my-auto mx-2" /> : null}
            </ButtonToolbar>
        </>);
    }
}
export default Qpessoas


