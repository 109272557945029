import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import TextField from '@material-ui/core/TextField';
import { Button, ButtonToolbar } from 'react-bootstrap';
import api from '../../src/services/api'
import CircularProgress from '@material-ui/core/CircularProgress';




export default class Faixa extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: [],
            button: false,
            faixa:[{
                idade: '0 a 18',
                quantidade: ''
              },
              {
                idade: '19 a 23',
                quantidade: ''
              },
              {
                idade: '24 a 28',
                quantidade: ''
              },
              {
                idade: '29 a 33',
                quantidade: ''
              },
              {
                idade: '34 a 38',
                quantidade: ''
              },
              {
                idade: '39 a 43',
                quantidade: ''
              },
              {
                idade: '44 a 48',
                quantidade: ''
              },
              {
                idade: '49 a 53',
                quantidade: ''
              },
              {
                idade: '54 a 58',
                quantidade: ''
              },
              {
                idade: '59+',
                quantidade: ''
              },]

            
        }
    }
    handleChangeFaixa(event, number) {
        
        const { faixa } = this.state;
         faixa[number].quantidade = event.replace(/\D/g, '');;

    // update state
    this.setState({
        faixa,
    });
    
    const formData = localStorage.getItem('leadDental')
    const data = JSON.parse(formData)
    data.faixa = this.state.faixa
    localStorage.setItem('leadDental', JSON.stringify(data))

    }
    async UpgradeLead() {
        const idLead = localStorage.getItem('idLead')

        const data = {
            id_origem: this.props.idorigem,
            id: parseInt(idLead),
            faixa: this.state.faixa
        }

        try {
            let response = await api.post('integracao/cadastro', data)
            //console.log(response);
            if (response.data.status === 'error') {
                this.setState({ error: response.data.critica })
                this.setState({ button: !this.state.button })

            }
            if (response.data.status === "success") {
                this.props.nextStep()
            }
        } catch (err) {
            console.error(err)
        }
    }

    async UpdateLeadSaudeDental() {
        const idLead = localStorage.getItem('IdLeadSaudeDental')

        const data = {
            id_origem: this.props.idorigem,
            id: parseInt(idLead),
            faixa: this.state.faixa
        }

        try {
            let response = await api.post('integracao/cadastro', data)
            //console.log(response);
            if (response.data.status === 'error') {
                this.setState({ error: response.data.critica })
                this.setState({ button: !this.state.button })

            }
            if (response.data.status === "success") {
                this.props.nextStep()
            }
        } catch (err) {
            console.error(err)
        }
    }

    continue = e => {
        e.preventDefault();
        this.setState({ button: !this.state.button })
        const idop = this.props.idop;
        if(idop !== '11'){
            this.setState({ error: [] })
            this.setState({ button: !this.state.button })
            this.UpgradeLead()
        }

        if(idop === '11'){
            this.setState({ error: [] })
            this.setState({ button: !this.state.button })
            this.UpdateLeadSaudeDental();
        }
        // this.UpgradeLead()
        //console.log(this.state.faixa);
        // if (this.props.faixa.length === 0) {
        //     //his.props.nextStep()
        // } else {
        //     this.setState({ button: !this.state.button })
        //     //this.UpgradeLead()
        // }
    };
    voltar = e => {
        e.preventDefault();
        this.props.prevStep();
    };
    render() {

        const numbers = ['0 a 18', '19 a 23', '24 a 28', '29 a 33', '34 a 38', '39 a 43', '44 a 48', '49 a 53', '54 a 58', '59+'];
        const listItems = numbers.map((number, i) =>
            <Col key={i} md={6} xs={6} className="my-2">
                <TextField
                    key={i}
                    type='tel'
                    id={`outlined-full-width${number}`}
                    name={`faixa`}
                    value={this.state.faixa[i].quantidade}
                    label={`Faixa ${number}`}
                    placeholder={`${number}`}
                    onChange={(e) => this.handleChangeFaixa(e.target.value, i)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                />
            </Col>
        );

        return (
            <Col md={12} className="px-0 mt-4">

                <h5 className="text-center my-5 animated fadeIn">Informe a quantidade por faixa de idade dos dependentes e titular do seu plano</h5>
                <br />
                <Row>
                    {listItems}
                </Row>
                <ButtonToolbar className="mt-3">
                    <Button
                        size="lg"
                        variant="outline-default"
                        onClick={this.voltar}
                    >Voltar
                            </Button>
                    <Button
                        size="lg"
                        variant="outline-primary"
                        onClick={this.continue}
                        disabled={this.state.button}
                    >Continuar
                            </Button>
                            {this.state.button ? <CircularProgress size={24} className="my-auto mx-2" /> : null}
                </ButtonToolbar>
            </Col>
        )

    }
}

