import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import api from '../../src/services/api'


export default class Obrigado extends Component {
    componentDidMount() {
        this.props.progresso();
        this.UpgradeLead();
        this.UpgradeLeadDental();
        this.UpdateLeadSaudeDental();
        if(this.props.redirect){
            let url_redirect = decodeURIComponent(decodeURIComponent(this.props.redirect));
            window.location = `${url_redirect}`
        }
    }

    async UpgradeLead() {
        const idLead = localStorage.getItem('idLead')
        console.log('------ ID LEAD SAUDE ------');
        console.log(idLead);
        console.log('---------------------------');

        const data = {
            id_origem: this.props.idorigem,
            id: parseInt(idLead),
            cadastro_finalizado: 1
        }

        try {
            let response = await api.post('integracao/cadastro', data)
            console.log(response);
            localStorage.removeItem('leadDental')
            localStorage.removeItem('idDental')
            localStorage.removeItem('idLead')
        } catch (err) {
            console.error(err)
        }
    }

    async UpgradeLeadDental() {
        
        const idDental = localStorage.getItem('idDental')

            if (idDental != null) {
                    console.log('------ ID LEAD DENTAL ------');
                    console.log(idDental);
                    console.log('---------------------------');

            const data = {
                id_origem: 6,
                id: parseInt(idDental),
                cadastro_finalizado: 0
            }

            try {
                let response = await api.post('integracao/cadastro', data)
                console.log(response);
            } catch (err) {
                console.error(err)
            }

        }else {
            console.log('nao possue id')
        }
    }


    async UpdateLeadSaudeDental() {
        
        const idDental = localStorage.getItem('IdLeadSaudeDental')
        //const idop = this.props.idop

            if (idDental != null) {
                    console.log('------ ID LEAD PLANO DENTAL ------');
                    console.log(idDental);
                    console.log('---------------------------');

            const data = {
                id_origem: 6,
                id: parseInt(idDental),
                cadastro_finalizado: 0
            }

            try {
                let response = await api.post('integracao/cadastro', data)
                console.log(response);
            } catch (err) {
                //console.error(err)
            }

        }else {
            console.log('nao possue id')
        }
    }

    

    msgFinal = (redirect=null) => {
        if(redirect){
            return (
                <p className="text-secondary">Você será redirecionado para <strong>{this.props.redirect}</strong> em alguns instantes...</p>
            );
        }
        let url_trada = decodeURIComponent(decodeURIComponent(this.props.url));
        return (
            <p><a className="text-uppercase text-center mx-auto" href={`${url_trada}`}>Voltar para o site</a></p>
        );
    };

    render() {
        return (
            <Col className="obrigado">
                <h1 className="text-center">Finalizamos, obrigado!</h1>
                <h2>Agora é com a gente...</h2>
                <p>Nas próximas horas um dos nossos corretores entrará em contato com você. Fique atento!</p>
                <div>{this.msgFinal(this.props.redirect)}</div>
            </Col>
        );
    }
}
