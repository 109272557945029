import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextField from '@material-ui/core/TextField';
import { Button, ButtonToolbar } from 'react-bootstrap';
import api from '../../src/services/api'
import CircularProgress from '@material-ui/core/CircularProgress';



export default class Dependentes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: [],
            qp: [],
            button: false,
            
            titular:[{
                dependente: 'titular',
                idade: ''
              }],
            dependentes:[{
                dependente: 'dependente 1',
                idade: ''
              },
              {
                dependente: 'dependente 2',
                idade: ''
              },
              {
                dependente: 'dependente 3',
                idade: ''
              },
              {
                dependente: 'dependente 4',
                idade: ''
              },
              {
                dependente: 'dependente 5',
                idade: ''
              },]
        }

    }

    componentDidMount() {
        const { qPessoas } = this.props
        //console.log(qPessoas);


        this.setState({ qp: qPessoas })


    }

    handleChangeTitular(event) {
        const { titular } = this.state;
        titular[0].idade = event.replace(/\D/g, '');

    // update state
    this.setState({
        titular,
    });

    const formData = localStorage.getItem('leadDental')
    const data = JSON.parse(formData)
    data.titular = this.state.titular
    localStorage.setItem('leadDental', JSON.stringify(data))
    }

    handleChangeDependentes(event, number) {
        const { dependentes } = this.state;
        dependentes[number].idade = event.replace(/\D/g, '');

    // update state
    this.setState({
        dependentes,
    });
    const formData = localStorage.getItem('leadDental')
    const data = JSON.parse(formData)
    data.dependentes = this.state.dependentes
    localStorage.setItem('leadDental', JSON.stringify(data))
}

    async UpgradeLead() {
        const idLead = localStorage.getItem('idLead')

        var hege = this.state.titular;
        var stale = this.state.dependentes;
        var children = hege.concat(stale);
        
        const data = {
            id_origem: this.props.idorigem,
            id: parseInt(idLead),
            dependente: children,
        }

        try {
            let response = await api.post('integracao/cadastro', data)
            //console.log(response);
            if (response.data.status === 'error') {
                this.setState({ error: response.data.critica })
                this.setState({ button: !this.state.button })

            }
            if (response.data.status === "success") {
                this.props.nextStep()
            }
        } catch (err) {
            console.error(err)
        }
    }

    async UpdateLeadSaudeDental() {
        const idLead = localStorage.getItem('IdLeadSaudeDental')

        var hege = this.state.titular;
        var stale = this.state.dependentes;
        var children = hege.concat(stale);
        
        const data = {
            id_origem: this.props.idorigem,
            id: parseInt(idLead),
            dependente: children,
        }

        try {
            let response = await api.post('integracao/cadastro', data)
            //console.log(response);
            if (response.data.status === 'error') {
                this.setState({ error: response.data.critica })
                this.setState({ button: !this.state.button })

            }
            if (response.data.status === "success") {
                this.props.nextStep()
            }
        } catch (err) {
            console.error(err)
        }
    }


    continue = e => {
        e.preventDefault();
        this.setState({ button: !this.state.button })
        const idop = this.props.idop;
        if(idop !== '11'){
            this.setState({ error: [] })
            this.setState({ button: !this.state.button })
            this.UpgradeLead()
        }

        if(idop === '11'){
            this.setState({ error: [] })
            this.setState({ button: !this.state.button })
            this.UpdateLeadSaudeDental();
        }

    };
    voltar = e => {
        e.preventDefault();
        this.props.prevStep();
    };




    render() {
        const { qPessoas } = this.props
        let lo;
        if (qPessoas > 6) {
            lo = 6 - 1;
        } else {
            lo = parseInt(qPessoas) - 1;
        }
        const numbers = [...Array(lo).keys()].map(x => ++x);
        const listItems = numbers.map((number, i) =>
            <Col key={i} md={6} xs={6} className="my-2">
                <TextField
                    id="outlined-full-width"
                    name="idade"
                    value={this.state.dependentes[i].idade}
                    label={`Dependente ${number}`}
                    placeholder="Idade do dependente"
                    onChange={(e) => this.handleChangeDependentes(e.target.value, i)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                />
            </Col>
        );
        return (<>
            <h5 className="text-center my-5 animated fadeIn">Nos informe a idade do titular e dos dependentes.</h5>
            <Row>
                <Col md={6} xs={6} className="my-2">
                    <TextField
                        id="outlined-full-width"
                        name="idade"
                        label={`Titular`}
                        value={this.state.titular.idade}
                        placeholder="Idade do titular"
                        onChange={(e) => this.handleChangeTitular(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                </Col>

                {listItems}
            </Row>
            <ButtonToolbar className="mt-3">
                <Button
                    size="lg"
                    variant="outline-default"
                    onClick={this.voltar}
                >Voltar
                            </Button>
                <Button
                    size="lg"
                    variant="outline-primary"
                    onClick={this.continue}
                    disabled={this.state.button}
                >Continuar
                            </Button>
                            {this.state.button ? <CircularProgress size={24} className="my-auto mx-2" /> : null}
            </ButtonToolbar>
        </>
        )

    }
}

