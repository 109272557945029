import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Button, ButtonToolbar } from 'react-bootstrap';
import axios from 'axios'
import api from '../../src/services/api'    
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';




export class Cep extends Component {

    constructor(props) {
        super(props);
        this.state = {
            logradouro: [],
            bairro: [],
            uf: [],
            localidade: [],
            cep: [],
            data: [],
            complemento:[],
            button: false,
            error:[]
            
        }
        this.handleChange = this.handleChange.bind(this);
    }


    async UpgradeLead() {
        const idLead = localStorage.getItem('idLead')      
        const data = {
            id_origem: this.props.idorigem,
            id: parseInt(idLead),
            bairro: this.state.bairro,
            cep: document.querySelector("#cep").value,
            cidade: this.state.localidade,
            estado: this.state.uf,
            endereco: this.state.logradouro,
            complemento:this.state.complemento
        }
  
        try {
            let response = await api.post('integracao/cadastro', data)
            //console.log(response);

            if (response.data.status === 'error') {
                this.setState({ error: response.data.critica })
                this.setState({ button: !this.state.button })

            }
            if (response.data.status === "success") {
                localStorage.setItem('idLead', response.data.lead.dados.id)
                this.props.nextStep()
            }
        } catch (err) {
            console.error(err)
        }
    }

    async UpdateLeadSaudeDental() {
        const idLead = localStorage.getItem('IdLeadSaudeDental')

        const data = {
            id_origem: this.props.idorigem,
            id: parseInt(idLead),
            bairro: this.state.bairro,
            cep: document.querySelector("#cep").value,
            cidade: this.state.localidade,
            estado: this.state.uf,
            endereco: this.state.logradouro,
            complemento:this.state.complemento
        }

        try {
            let response = await api.post('integracao/cadastro', data)
            //console.log(response);
            if (response.data.status === 'error') {
                this.setState({ error: response.data.critica })
                this.setState({ button: !this.state.button })

            }
            if (response.data.status === "success") {
                this.props.nextStep()
            }
        } catch (err) {
            console.error(err)
        }
    }


    handleChange = input => e => {
        this.setState({ [input]: e.target.value });
    }
    

    continue = e => {
        e.preventDefault();
        this.setState({ error: [] })
        this.setState({ button: !this.state.button })
        
        const idop = this.props.idop;
        if(idop !== '11'){
            this.setState({ error: [] })
            this.setState({ button: !this.state.button })
            this.UpgradeLead()
        }

        if(idop === '11'){
            this.setState({ error: [] })
            this.setState({ button: !this.state.button })
            this.UpdateLeadSaudeDental();
        }
        
    };
    voltar = e => {
        e.preventDefault();
        this.props.prevStep();
    };



    preencheDados = async () => {
        let numero1 = document.querySelector("#cep").value;
        const results = await axios.get(`https://viacep.com.br/ws/${numero1}/json/`);
        //console.log(results.data);
        this.setState({
            logradouro: results.data.logradouro,
            bairro: results.data.bairro,
            complemento: results.data.complemento,
            uf: results.data.uf,
            localidade: results.data.localidade,
        })
        const formData = localStorage.getItem('leadDental')
        const data = JSON.parse(formData)
        data.logradouro = this.state.logradouro
        data.bairro = this.state.bairro
        data.complemento = this.state.complemento
        data.uf = this.state.uf
        data.localidade = this.state.localidade
        localStorage.setItem('leadDental', JSON.stringify(data))
        //console.log(this.state);
    }
    
    render() {
        return (<>
            <h5 className="text-center my-5 animated fadeIn">Estamos quase terminando, nos informe seu endereço.</h5>
            <Row>
                <Col className="col-md-12 text-left animated fadeInLeft">
                    <Row>
                        <Col className="col-md-12 col-12 ">
                            {!this.state.ready && (
                                <>
                                    <Row>
                                        <Col md={12}>
                                            <TextField
                                                label="CEP do seu endereço"
                                                variant="outlined"
                                                name="cep"
                                                id='cep'
                                                
                                                onChange={this.props.handleChange('cep')}
                                                onBlur={() => this.preencheDados('cep')}
                                                fullWidth
                                            />
                                        </Col>


                                    </Row>

                                    <Row>

                                        <Col md={12} className="mt-3">
                                            <TextField
                                                label="Estado"
                                                variant="outlined"
                                                name="uf"
                                                id='uf'
                                                value={this.state.uf}
                                                onChange={this.handleChange('uf')}
                                                fullWidth
                                            />
                                        </Col>
                                        <Col md={12} className="mt-3">
                                            <TextField
                                                label="Cidade"
                                                variant="outlined"
                                                name="localidade"
                                                id='localidade'
                                                value={this.state.localidade}
                                                onChange={this.handleChange('localidade')}
                                                fullWidth
                                            />
                                        </Col>
                                    </Row>
                                </>

                            )}

                            {this.state.ready && (
                                <>
                                    <Row>
                                        <Col md={6}>
                                            <TextField
                                                label="Cep da sua cidade"
                                                variant="outlined"
                                                name="cep"
                                                id='cep'
                                                value={this.state.cep}
                                                onChange={this.handleChange('cep')}
                                                fullWidth
                                            />
                                        </Col>
                                        <Col md={6} className="mt-3 mt-md-0">
                                            <TextField
                                                label="Endereço"
                                                variant="outlined"
                                                value={this.state.endereco}
                                                name="endereco"
                                                id='endereco'
                                                onChange={this.handleChange('endereco')}
                                                fullWidth
                                            />

                                        </Col>

                                    </Row>
                                    <Row className="mt-3">
                                        <Col md={3}>
                                            <TextField
                                                label="Numero"
                                                variant="outlined"
                                                name="numero"
                                                id='numero'
                                                onChange={this.handleChange('numero')}
                                                fullWidth
                                            />
                                        </Col>
                                        <Col md={9} className="mt-3 mt-md-0">
                                            <TextField
                                                label="Bairro"
                                                variant="outlined"
                                                name="bairro"
                                                id='bairro'
                                                value={this.state.bairro}
                                                onChange={this.handleChange('bairro')}
                                                fullWidth
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} className="mt-3">
                                            <TextField
                                                label="Complemento"
                                                variant="outlined"
                                                name="complemento"
                                                id='complemento'
                                                value={this.state.complemento}
                                                onChange={this.handleChange('complemento')}
                                                fullWidth
                                            />
                                        </Col>
                                        <Col md={6} className="mt-3">
                                            <TextField
                                                label="Estado"
                                                variant="outlined"
                                                name="estado"
                                                id='estado'
                                                value={this.state.estado}
                                                onChange={this.handleChange('estado')}
                                                fullWidth
                                            />
                                        </Col>
                                        <Col md={12} className="mt-3">
                                            <TextField
                                                label="Cidade"
                                                variant="outlined"
                                                name="cidade"
                                                id='cidade'
                                                value={this.state.cidade}
                                                onChange={this.handleChange('cidade')}
                                                fullWidth
                                            />
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </Col>

                    </Row>
                    <Row>
                        <Col md={12} className="mt-3">
                            <ButtonToolbar>
                                <Button
                                    size="lg"
                                    variant="outline-default"
                                    onClick={this.voltar}
                                >Voltar
                            </Button>
                                <Button
                                    size="lg"
                                    variant="outline-primary"
                                    onClick={this.continue}
                                    disabled={this.state.button}
                                >Continuar
                            </Button>
                            {this.state.button ? <CircularProgress size={24} className="my-auto mx-2" /> : null}
                            </ButtonToolbar>

                        </Col>
                    </Row>
                </Col>
            </Row>
        </>)
    }
}
export default Cep


