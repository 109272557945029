import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Button, ButtonToolbar } from 'react-bootstrap';
import Info from './subcomponentes/Info'
import api from '../../src/services/api'
import CircularProgress from '@material-ui/core/CircularProgress';



export default class Atendimento extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: [],
            button: false,
        }
    }
    
    async UpgradeLead() {
        const idLead = localStorage.getItem('idLead')

        const data = {
            id_origem: this.props.idorigem,
            id: parseInt(idLead),
            cobertura: this.props.atendimento
        }

        try {
            let response = await api.post('integracao/cadastro', data)
            //console.log(response);
            if (response.data.status === 'error') {
                this.setState({ error: response.data.critica })
                this.setState({ button: !this.state.button })

            }
            if (response.data.status === "success") {
                this.props.nextStep()
            }
        } catch (err) {
            console.error(err)
        }
    }

    async UpdateLeadSaudeDental() {
        const idLead = localStorage.getItem('IdLeadSaudeDental')

        const data = {
            id_origem: this.props.idorigem,
            id: parseInt(idLead),
            cobertura: this.props.atendimento
        }

        try {
            let response = await api.post('integracao/cadastro', data)
            //console.log(response);
            if (response.data.status === 'error') {
                this.setState({ error: response.data.critica })
                this.setState({ button: !this.state.button })

            }
            if (response.data.status === "success") {
                this.props.nextStep()
            }
        } catch (err) {
            console.error(err)
        }
    }

    handleChangeAtendimento(event) {
        this.props.handAtendimento(event)
        const formData = localStorage.getItem('leadDental')
        const data = JSON.parse(formData)
        data.cobertura = event
        localStorage.setItem('leadDental', JSON.stringify(data))
    }
    continue = e => {
        e.preventDefault();
        if (this.props.atendimento.length === 0) {
            this.props.nextStep()
        } else {
            this.setState({ button: !this.state.button })
            const idop = this.props.idop;
            if(idop !== '11'){
                this.setState({ error: [] })
                this.setState({ button: !this.state.button })
                this.UpgradeLead()
            }

            if(idop === '11'){
                this.setState({ error: [] })
                this.setState({ button: !this.state.button })
                this.UpdateLeadSaudeDental();
            }
        }
    };
    voltar = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    render() {
        const numbers = [
            {
                title: 'Nacional',
                msg: 'Plano com cobertura Nacional poderá abranger boa parte do território nacional. '
            },
            {
                title: 'Estadual',
                msg: 'Plano de Saúde Estadual  tem cobertura apenas em uma região específica.'
            },
            {
                title: 'Regional',
                msg: 'Plano com cobertura Regional poderá abranger boa parte do território regional.'
            }

        ];
        const listItems = numbers.map((item, i) =>

            <Row key={i}>
                <Col md={1} xs={1} className="my-auto text-center text-md-right px-md-0">
                    <Info title={`${item.title}`} msg={`${item.msg}`} />
                </Col>
                <Col md={11} xs={11}>
                    <Form.Check
                        custom
                        type='radio'
                        name='atendimento'
                        id={`${item.title}`}
                        label={`${item.title}`}
                        value={`${item.title}`}
                        onChange={(e) => this.handleChangeAtendimento(e.target.value)}
                        className="my-3"
                    />
                </Col>
            </Row>

        );
        return (
            <>
                <h5 className="text-center my-5 animated fadeIn">Escolha a área de cobertura do seu plano.</h5>
                {listItems}
                <ButtonToolbar className="mt-3">
                    <Button
                        size="lg"
                        variant="outline-default"
                        onClick={this.voltar}
                    >Voltar
                            </Button>
                    <Button
                        size="lg"
                        variant="outline-primary"
                        onClick={this.continue}
                        disabled={this.state.button}
                    >Continuar
                            </Button>
                            {this.state.button ? <CircularProgress size={24} className="my-auto mx-2" /> : null}
                </ButtonToolbar>
            </>
        )
    }
}

