import React from 'react';
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

// import { Container } from './styles';

export default function Info(props) {
    return (
        <OverlayTrigger
            trigger="click"
            placement="top"
            overlay={
                <Popover id="popover-basic">
                    <Popover.Title as="h3"> {props.title}</Popover.Title>
                    <Popover.Content>
                        {props.msg}
                    </Popover.Content>
                </Popover>
            }>
            <span className="info pt-2"><i className="fas fa-question-circle"></i></span>
        </OverlayTrigger>
    );
}
