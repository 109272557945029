import React, { Component } from 'react';
import { Button, ButtonToolbar } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputMask from 'react-input-mask';
import api from '../../src/services/api'
import CircularProgress from '@material-ui/core/CircularProgress';


export class Telefone extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: [],
            status: [],
            button: false,
            estoqueLeadsDental: 0
        }
    }

    async getEstoque() {
        try {
            const apiUrl = 'https://app.leadmark.com.br/integracao/quantidade';
            const response = await fetch(apiUrl);
            const responseToJson = await response.json()
            this.setState({ estoqueLeadsDental: responseToJson[1].qtde})
        } catch (error) {
            console.log(error)
        }
       
    }

    async componentDidMount(){
        localStorage.removeItem('idDental')
        localStorage.removeItem('idLead')
        localStorage.removeItem('IdLeadSaudeDental')
        // var id_produto = this.props.idop;

        // await this.getEstoque()

        // if(id_produto === '11' && this.state.estoqueLeadsDental > '200') {
        //    this.props.nextStep(16)
        // }
    }

    async cadastraLead() {
        var tel = this.props.telefone.toString()
        var telefoneLead = tel.replace("+55", "")
        telefoneLead = telefoneLead.replace(/[^\d]+/g, '')

        const data = {
            id_origem: this.props.idorigem,
            id_segmento: this.props.idsegmento,
            id_tipo: 3,
            id_produto: this.props.idop,
            nome: this.props.nome,
            telefones: [{ numero: telefoneLead, whatsapp: this.props.temzap ? '1' : '0' }]
        }

        //console.log(data.id_produto)

        if (typeof window !== "undefined") {
            if (window.fbq != null) { 
                window.fbq('trackCustom', 'Conversões')
            }
        }

        try {
            let response = await api.post('integracao/cadastro', data)
            //console.log(response);

            // const formData = localStorage.getItem('leadDental')
            // const telefone = JSON.parse(formData)
            // telefone.telefones = [{ numero: telefoneLead, whatsapp: this.props.temzap ? '1' : '0' }]
            // telefone.id_produto = this.props.idop
            // telefone.nome = this.props.nome
            // localStorage.setItem('leadDental', JSON.stringify(telefone))
            const dados = {
                nome: this.props.nome,
                telefones: [{ numero: telefoneLead, whatsapp: this.props.temzap ? '1' : '0' }],
                id_produto: this.props.idop,
            }

            localStorage.setItem('leadDental', JSON.stringify(dados))

            if (response.data.status === 'error') {
                this.setState({ error: response.data.critica })
                this.setState({ button: !this.state.button })

            }
            if (response.data.status === "success") {
                localStorage.setItem('idLead', response.data.lead.dados.id)
                this.props.cadastrarEndereco()
                this.props.nextStep()
            }
        } catch (err) {
            console.error(err)
        }
    }

    async UpdateLeadSaudeDental() {
        var tel = this.props.telefone.toString()
        var telefoneLead = tel.replace("+55", "")
        telefoneLead = telefoneLead.replace(/[^\d]+/g, '')

        const data = {
            id_origem: this.props.idorigem,
            id_segmento: this.props.idsegmento,
            id_tipo: 3,
            id_produto: this.props.idop,
            nome: this.props.nome,
            telefones: [{ numero: telefoneLead, whatsapp: this.props.temzap ? '1' : '0' }]
        }

        localStorage.setItem('LeadSaudeDental', JSON.stringify(data))
        localStorage.setItem('leadDental', JSON.stringify(data))
        this.props.nextStep()
    }

    handleChangeTelefone(event) {
        if (this.props.telefone === '55' || this.props.telefone === '' || this.props.telefone.length < 15) {
            this.setState({ error: [] })
        }
        this.props.handTel(event) 
    }

    continue = e => {
        e.preventDefault();
        if (this.props.telefone.length > 15) {
            const idop = this.props.idop;
            this.setState({ error: [] })
            this.setState({ button: !this.state.button })
            if(idop !== '11'){
                this.cadastraLead()
            }

            if(idop === '11'){
                this.UpdateLeadSaudeDental();
            }
        }
        else {
            this.setState({ error: 'coloque um telefone valido!' })
        }

    };
    voltar = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    render() {
        return (
            <>
                <h5 className="text-center my-5 animated fadeIn">Agora, informe um telefone válido para contato.</h5>
                <Form.Label style={{ fontSize: '10px' }}>DDD + Número</Form.Label>
                <InputMask
                    type="tel"
                    className="form-control btn-lg"
                    placeholder="(XX) XXXX-XXXX"
                    mask="+55 99 99999 9999"
                    maskChar={null}
                    value={this.props.telefone}
                    onChange={(e) => this.handleChangeTelefone(e.target.value)}
                />
                <small style={{ color: 'red' }}>{this.state.error}</small>
                <Form.Check
                    custom
                    type='checkbox'
                    name='temzap'
                    id='temzap'
                    label='Esse telefone é meu whatsapp!'
                    onChange={this.props.handleZap}
                    defaultChecked={this.props.temzap}
                    className="mt-4"
                />
                <ButtonToolbar className="mt-3">
                    <Button
                        size="lg"
                        variant="outline-default"
                        onClick={this.voltar}
                    >Voltar
                            </Button>
                    <Button
                        size="lg"
                        variant="outline-primary"
                        onClick={this.continue}
                        disabled={this.state.button}
                    >Continuar</Button>
                    {this.state.button ? <CircularProgress size={24} className="my-auto mx-2" /> : null}
                </ButtonToolbar>
            </>);
    }
}

export default Telefone


