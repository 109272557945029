import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import axios from 'axios'
import { Helmet } from "react-helmet";
import ReactPixel from 'react-facebook-pixel';
import api from '../../src/services/api'


//import Dados from './Dados'
import Desejo from './Desejo';
import Nome from './Nome';
import Telefone from './Telefone';
import Cep from './Cep';
import Qpessoas from './Qpessoas';
import Cooparticipacao from './Cooparticipacao';
import Idade from './Idade';
import Acomodacao from './Acomodacao';
import Reenbolso from './Reenbolso';
import Pdental from './Pdental'
import Atendimento from './Atendimento';
import Obrigado from './Obrigado';
import FamiliarEmpresarial from './FamiliarEmpresarial'
import Cnpj from './Cnpj'
import Dependentes from './Dependentes'
import Faixa from './Faixa'
import RedirectDental from './RedirectDental'




class Main extends Component {
   state = {
      step: 1,
      corPadrao: '#12866f',
      progresso: 1,
      idop: 66,
      idorigem:6,
      url:'https://sejacliente.com.br',
      urlProduto:'https://www.amildental.com.br/plano-odontologico/dental-205-para-1-ou-duas-pessoas/961907_DENTAL?corretora=59544&codigoCorretor=61339644304&utm_source=comunicaamil&utm_medium=link',
      desejo: [],
      nome: [],
      telefone: [],
      temzap: true,
      cep: [],
      endereco: [],
      bairro: [],
      cidade: [],
      estado: [],
      pais:[],
      qPessoas: [],
      tipo: '1',
      tipoUrl:[],
      idade: [],
      dependentes: [],
      faixa: [],
      cooparticipacao: [],
      acomodacao: [],
      reenbolso: [],
      atendimento: [],
      pdental: [],
      cnpj: [],
      contato: [],
      zap:false,

latitude:[],
longitude:[],


      
      error: 1,
   }



   async UpgradeLead() {
      const idLead = localStorage.getItem('idLead')      
      const data = {
          id_origem: this.state.idorigem,
          id: parseInt(idLead),
          bairro: this.state.bairro,
          cep: this.state.cep,
          cidade: this.state.cidade,
          estado: this.state.estado,
          pais: this.state.pais,
          endereco: this.state.endereco,
          id_tipo:this.state.tipoUrl,
      }

      try {
          let response = await api.post('integracao/cadastro', data)
          //console.log(response);
          if (response.data.status === 'error') {
              

          }
          if (response.data.status === "success") {
             this.setState({error:0})
          }
      } catch (err) {
          console.error(err)
      }
  }


   cadastrarEndereco = () => {
      var options = {
         enableHighAccuracy: true,
         timeout: 5000,
         maximumAge: 0
       };

      navigator.geolocation.getCurrentPosition(this.success, this.error, options);
   }

   success = (pos) => {
      var crd = pos.coords;
      this.setState({latitude:crd.latitude, longitude:crd.longitude, error:0})
      this.solicitaApiGoogle()
      
    };
    
    error = (err) =>{
      console.warn('ERROR(' + err.code + '): ' + err.message);
    };

   async solicitaApiGoogle(){
      await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.state.latitude},${this.state.longitude}&key=AIzaSyC9-vVWGMT8aWHkyHs4xQTg2mfBpF8f8h4`)
      .then((response) => {
         //console.log(response.data.results[0]);
         this.setState({
            endereco: response.data.results[0].address_components[1].short_name,
            bairro: response.data.results[0].address_components[2].long_name,
            cidade: response.data.results[0].address_components[3].long_name,
            estado: response.data.results[0].address_components[4].long_name,
            pais: response.data.results[0].address_components[5].long_name,
            cep: response.data.results[0].address_components[6].long_name,
         })

         this.UpgradeLead()
         const formData = localStorage.getItem('leadDental')
         const data = JSON.parse(formData)
         data.endereco = this.state.endereco
         data.bairro = this.state.bairro
         data.cidade = this.state.cidade
         data.estado = this.state.estado
         data.pais = this.state.pais
         data.cep = this.state.endereco

         localStorage.setItem('leadDental', JSON.stringify(data))

         //console.log(this.state);
      })
      .catch((error) => {
         console.log(error);
      });
   }

   
    componentDidMount() {
       //this.cadastrarEndereco()
       const uri = decodeURI(window.location.search)
      const params = uri.substr(1).split('&');

      //console.log();
      
      let paramArray = {};
      for (var i = 0; i < params.length; i++) {
         //Dividir os parametros chave e valor
         var param = params[i].split('=');

         //Adicionar ao objeto criado antes
         paramArray[param[0]] = param[1];
      }

      const idop = paramArray['idop']
      const url = paramArray['url']
      const cor = paramArray['cor']
      const nome = paramArray['nome']
      const desejo = paramArray['desejo']
      const step = paramArray['step']
      const tipoUrl = paramArray['tipo']
      const zap = paramArray['whatzapp']
      const segmento = paramArray['segmento'] ?? 1
      const redirect = paramArray['redirect'] ?? ''
      const urlProduto = paramArray['urlProduto']

      
      if(zap === '1'){
         this.setState({idorigem:8})
      }

      this.parametros(idop, url, cor, nome, desejo, step, tipoUrl, zap, segmento, redirect, urlProduto)
   }
   
   
   

   componentDidUpdate(){
      //console.log(decodeURI(window.location.href));

   }
   progresso = () => {
      this.setState({ progresso: 100 })
   }

   parametros = (op, url, cor, nome, desejo, step, tipoUrl, zap, segmento, redirect, urlProduto) => {
      if (op) {
         this.setState({ idop: op })
      }
      if (url) {
         this.setState({ url:url })
      }
      if (cor) {
         this.setState({ corPadrao:`#`+cor })
      }
      if (nome) {


         const replaceSpecialChars = (str) => {
            return str.normalize('NFD')
               .replace(/[\u0300-\u036f]/g, '') // Remove acentos
               .replace(/([^\w]+|\s+)/g, ' ') // Substitui espaço e outros caracteres por hífen
               .replace(/\-\-+/g, ' ')	// Substitui multiplos hífens por um único hífen
               .replace(/(^-+|-+$)/, ''); // Remove hífens extras do final ou do inicio da string
         }

      
         
         this.setState({ nome:replaceSpecialChars(nome).toLowerCase() })
      }
      if (desejo) {
         this.setState({ desejo:parseInt(desejo)})
      }
      if (step && step <= 3) {
         this.setState({ step:parseInt(step) })
      }
      if (tipoUrl) {
         this.setState({ tipoUrl:tipoUrl })
      }
      if (zap) {
         this.setState({ zap:true})
      }
      if (segmento) {
         this.setState({ segmento: parseInt(segmento) })
      }
      if (redirect) {
         this.setState({ redirect: redirect })
      }
      if (urlProduto) {
         this.setState({ urlProduto: urlProduto })
      }
   }
   
   
   nextStep = (step_option=null) => {
      //console.log(this.state.segmento)
      //console.log(this.state.step)
      const { step, progresso } = this.state
      if(step_option){
         this.setState({
            step: step_option,
            progresso: progresso + 10
         })  
      } else {
         this.setState({
            step: step + 1,
            progresso: progresso + 10
         })
      }
   }
   prevStep = () => {
      const { step, progresso } = this.state
      this.setState({
         step: step - 1,
         progresso: progresso - 10
      })
   }
   handP = (evento) => {
      this.setState({ nome: evento })
   }
   handDesejo = (evento) => {
      this.setState({ desejo: evento })
   }
   handTel = (evento) => {
      this.setState({ telefone: evento })
   }
   handleZap = () => {
      this.setState({ temzap: !this.state.temzap })
   }
   handQpessoas = (evento) => {
      this.setState({ qPessoas: evento })
      if (evento === '1') {
         this.setState({ tipo: '1' })
      }
      else {
         this.setState({ tipo: [] })
      }
   }
   handIdade = (evento) => {
      this.setState({ idade: evento })
   }
   handCnpj = (evento) => {
      this.setState({ cnpj: evento })
   }
   handFamiliarEmpresarial = (evento) => {
      this.setState({ tipo: evento })
   }
   handCoo = (evento) => {
      this.setState({ cooparticipacao: evento })
   }
   handAcomodacao = (evento) => {
      this.setState({ acomodacao: evento })
   }
   handAtendimento = (evento) => {
      this.setState({ atendimento: evento })
   }
   handReenbolso = (evento) => {
      this.setState({ reenbolso: evento })
   }
   handPdental = (evento) => {
      this.setState({ pdental: evento })
   }
   handleChange = input => e => {
      this.setState({ [input]: e.target.value });
   }

   showStep = () => {
      const { step, segmento } = this.state;
      if (step === 1)
         return <Desejo
            handleChange={this.handleChange}
            handDesejo={this.handDesejo}
            nextStep={this.nextStep}
            desejo={this.state.desejo}
         />;
      if (step === 2)
         return <Nome
            handleChange={this.handleChange}
            handP={this.handP}
            nome={this.state.nome}
            nextStep={this.nextStep}
            prevStep={this.prevStep}
         />;
      if (step === 3)
         return <Telefone
            handleChange={this.handleChange}
            handTel={this.handTel}
            handleZap={this.handleZap}
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            temzap={this.state.temzap}
            telefone={this.state.telefone}
            nome={this.state.nome}
            cadastrarEndereco={this.cadastrarEndereco}
            idop={this.state.idop}
            idorigem={this.state.idorigem}
            idsegmento={this.state.segmento}
         />;

      if (step === 4)
      if(this.state.tipoUrl === '1'){
         this.setState({qPessoas:1})
         this.nextStep()
      }else{
         return (<>
            <Qpessoas
            handleChange={this.handleChange}
            nextStep={this.nextStep}
            handQpessoas={this.handQpessoas}
            qPessoas={this.state.qPessoas}
            tipo={this.state.tipo}
            idop={this.state.idop}
            tipoPlano={this.tipoPlano}
            prevStep={this.prevStep}
            idorigem={this.state.idorigem}
         /></>);
      }
         
      if (step === 5) {
         if (this.state.qPessoas === '1') {
            return (
               <>
                  <h1>{this.step}</h1>
                  <Idade
                     idade={this.state.idade}
                     handIdade={this.handIdade}
                     idop={this.state.idop}
                     nextStep={segmento === 1 ? this.nextStep : () => this.nextStep(9)}
                     prevStep={this.prevStep}
                     idorigem={this.state.idorigem}
                  />
               </>
            )

         } else {
            
            return (
               <>
                  <FamiliarEmpresarial
                     handFamiliarEmpresarial={this.handFamiliarEmpresarial}
                     tipo={this.state.tipo}
                     nextStep={this.nextStep}
                     nome={this.state.nome}
                     prevStep={this.prevStep}
                     idop={this.state.idop}
                     urlTipo={this.state.tipoUrl}
                     idorigem={this.state.idorigem}

                  />

               </>
            )
         }
      }
      if (this.state.tipo === '2') {
         if (step === 6)
            if (this.state.qPessoas > 6)
               return (
                  <>
                     <Faixa
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        idop={this.state.idop}
                        idorigem={this.state.idorigem}

                     />
                  </>
               )
            else {
               return (
                  <>
                     <Dependentes
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        idop={this.state.idop}
                        qPessoas={this.state.qPessoas}
                        idorigem={this.state.idorigem}

                     />
                  </>
               )
            }
         if (step === 7)
            return (
               <>
                  <Cnpj
                     handCnpj={this.handCnpj}
                     nextStep={segmento === 1 ? this.nextStep : () => this.nextStep(11)}
                     prevStep={this.prevStep}
                     idop={this.state.idop}
                     cnpj={this.state.cnpj}
                     idorigem={this.state.idorigem}

                  />
               </>
            )

         if (step === 8)
            return (
               <>
                  <Cooparticipacao
                     handCoo={this.handCoo}
                     nextStep={this.nextStep}
                     prevStep={this.prevStep}
                     idop={this.state.idop}
                     cooparticipacao={this.state.cooparticipacao}
                     idorigem={this.state.idorigem}

                  />
                  
               </>
            );
         if (step === 9)
            return (
               <>
                  <Acomodacao
                     handAcomodacao={this.handAcomodacao}
                     nextStep={this.nextStep}
                     prevStep={this.prevStep}
                     idop={this.state.idop}
                     acomodacao={this.state.acomodacao}
                     idorigem={this.state.idorigem}


                  />
                  
               </>
            )
               ;

         if (step === 10)
            return (
               <>
                  <Reenbolso
                     handReenbolso={this.handReenbolso}
                     nextStep={this.nextStep}
                     prevStep={this.prevStep}
                     idop={this.state.idop}
                     reenbolso={this.state.reenbolso}
                     idorigem={this.state.idorigem}

                  />
                  
               </>
            )
         if (step === 11)
            return (
               <>
                  <Atendimento
                     handAtendimento={this.handAtendimento}
                     nextStep={this.nextStep}
                     prevStep={this.prevStep}
                     idop={this.state.idop}
                     atendimento={this.state.atendimento}
                     idorigem={this.state.idorigem}

                  />
                  
               </>
            )
         if (step === 12)
         return (
            <>
               <Pdental
                  handPdental={this.handPdental}
                  nextStep={this.nextStep}
                  prevStep={this.prevStep}
                  idop={this.state.idop}
                  tipo={this.state.tipo}
                  pdental={this.state.pdental}
                  idorigem={this.state.idorigem}
                  urlProduto={this.state.urlProduto}

               />
               
            </>
         )
         if (this.state.error === 1) {
            if (step === 13)
               return (
                  <>
                     <Cep
                        handleChange={this.handleChange}
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        idop={this.state.idop}
                        step={this.step}
                        idorigem={this.state.idorigem}

                     />
                  </>
               )
         }
         else {
            if (step === 13)
               return <Obrigado 
               progresso={this.progresso}
               url={this.state.url} 
               idop={this.state.idop}
               idorigem={this.state.idorigem}
               redirect={this.state.redirect}

               />
         }
         if (step === 14)
            return <Obrigado 
               progresso={this.progresso}
               url={this.state.url}
               idop={this.state.idop}
               idorigem={this.state.idorigem}
               redirect={this.state.redirect}
   
            />
         
         if (step === 16)
         return <RedirectDental 
            url={this.state.url}
            idop={this.state.idop}
            urlProduto={this.state.urlProduto}
            tipo={this.state.tipo}
            idorigem={this.state.idorigem}
            qPessoas={this.state.qPessoas}
            redirect={this.state.redirect}
         />
         
      }
   
      if (this.state.tipo === '3') {
         if (step === 6)
            if (this.state.qPessoas > 6){
               return (
                  <>
                     <Faixa
                        nextStep={segmento === 1 ? this.nextStep : () => this.nextStep(10)}
                        prevStep={this.prevStep}
                        idop={this.state.idop}
                        idorigem={this.state.idorigem}

                     />
                  </>
               )
            }
            else {
               return (
                  <>
                     <Dependentes
                        nextStep={segmento === 1 ? this.nextStep : () => this.nextStep(10)}
                        prevStep={this.prevStep}
                        idop={this.state.idop}
                        qPessoas={this.state.qPessoas}
                        idorigem={this.state.idorigem}

                     />
                  </>
               )
            }

         if (step === 7)
            return (
               <>
                  <Cooparticipacao
                     handCoo={this.handCoo}
                     nextStep={this.nextStep}
                     idop={this.state.idop}
                     prevStep={this.prevStep}
                     cooparticipacao={this.state.cooparticipacao}
                     idorigem={this.state.idorigem}

                  />
                  
               </>
            );
         if (step === 8)
            return (
               <>
                  <Acomodacao
                     handAcomodacao={this.handAcomodacao}
                     nextStep={this.nextStep}
                     prevStep={this.prevStep}
                     idop={this.state.idop}
                     acomodacao={this.state.acomodacao}
                     idorigem={this.state.idorigem}


                  />
               </>
            )

         if (step === 9)
            return (
               <>
                  <Reenbolso
                     handReenbolso={this.handReenbolso}
                     nextStep={this.nextStep}
                     prevStep={this.prevStep}
                     idop={this.state.idop}
                     reenbolso={this.state.reenbolso}
                     idorigem={this.state.idorigem}

                  />
               </>
            )
         if (step === 10)
            return (
               <>
                  <Atendimento
                     handAtendimento={this.handAtendimento}
                     nextStep={this.nextStep}
                     prevStep={this.prevStep}
                     idop={this.state.idop}
                     atendimento={this.state.atendimento}
                     idorigem={this.state.idorigem}

                  />
               </>
            )
         if (step === 11)
         return (
            <>
               <Pdental
                  handPdental={this.handPdental}
                  nextStep={this.nextStep}
                  prevStep={this.prevStep}
                  idop={this.state.idop}
                  tipo={this.state.tipo}
                  pdental={this.state.pdental}
                  idorigem={this.state.idorigem}
                  urlProduto={this.state.urlProduto}

               />
            </>
         )
         if (this.state.error === 1) {
               if (step === 12)
                  return (
                     <>
                        <Cep
                           handleChange={this.handleChange}
                           nextStep={this.nextStep}
                           prevStep={this.prevStep}
                           idop={this.state.idop}
                           step={this.step}
                           idorigem={this.state.idorigem}
   
                        />
                     </>
                  )
            }
         else {
               if (step === 12)
                  return <Obrigado 
                  progresso={this.progresso}
                  url={this.state.url}
                  idop={this.state.idop} 
                  idorigem={this.state.idorigem}
                  redirect={this.state.redirect}
   
                  />
            }
         if (step === 13)
            return <Obrigado 
               progresso={this.progresso}
               url={this.state.url} 
               idop={this.state.idop}
               idorigem={this.state.idorigem}
               redirect={this.state.redirect}

            />

         if (step === 16)
         return <RedirectDental 
            url={this.state.url}
            idop={this.state.idop}
            urlProduto={this.state.urlProduto}
            tipo={this.state.tipo}
            qPessoas={this.state.qPessoas}
            idorigem={this.state.idorigem}
            redirect={this.state.redirect}
         />

      }
      if (this.state.tipo === '1') {


         if (step === 6)
            return (
               <>
                  <Cooparticipacao
                     handCoo={this.handCoo}
                     nextStep={this.nextStep}
                     idop={this.state.idop}
                     prevStep={this.prevStep}
                     cooparticipacao={this.state.cooparticipacao}
                     idorigem={this.state.idorigem}

                  />
               </>
            );
         if (step === 7)
            return (
               <>
                  <Acomodacao
                     handAcomodacao={this.handAcomodacao}
                     nextStep={this.nextStep}
                     prevStep={this.prevStep}
                     idop={this.state.idop}
                     acomodacao={this.state.acomodacao}
                     idorigem={this.state.idorigem}
                  />
               </>
            )


         if (step === 8)
            return (
               <>
                  <Reenbolso
                     handReenbolso={this.handReenbolso}
                     nextStep={this.nextStep}
                     prevStep={this.prevStep}
                     idop={this.state.idop}
                     reenbolso={this.state.reenbolso}
                     idorigem={this.state.idorigem}
                  />
               </>
            )
         if (step === 9)
            return (
               <>
                  <Atendimento
                     handAtendimento={this.handAtendimento}
                     nextStep={this.nextStep}
                     prevStep={this.prevStep}
                     idop={this.state.idop}
                     atendimento={this.state.atendimento}
                     idorigem={this.state.idorigem}

                  />
               </>
            )
         if (step === 10)
         return (
            <>
               <Pdental
                  handPdental={this.handPdental}
                  nextStep={this.nextStep}
                  prevStep={this.prevStep}
                  pdental={this.state.pdental}
                  idop={this.state.idop}
                  idorigem={this.state.idorigem}
                  urlProduto={this.state.urlProduto}

               />
            </>
         )
         if (this.state.error === 1) {
               if (step === 11)
                  return (
                     <>
                        <Cep
                           handleChange={this.handleChange}
                           nextStep={this.nextStep}
                           prevStep={this.prevStep}
                           idop={this.state.idop}
                           step={this.step}
                           idorigem={this.state.idorigem}
   
                        />
                     </>
                  )
            }
         else {
               if (step === 11)
                  return <Obrigado 
                  progresso={this.progresso}
                  url={this.state.url} 
                  idop={this.state.idop}
                  idorigem={this.state.idorigem}
                  redirect={this.state.redirect}
   
                  />
            }   
         if (step === 12)
            return <Obrigado 
               progresso={this.progresso}
               url={this.state.url}
               idop={this.state.idop}
               idorigem={this.state.idorigem}
               redirect={this.state.redirect}
 
               />
         if (step === 16)
         return <RedirectDental 
            url={this.state.url}
            idop={this.state.idop}
            urlProduto={this.state.urlProduto}
            tipo={this.state.tipo}
            qPessoas={this.state.qPessoas}
            idorigem={this.state.idorigem}
            redirect={this.state.redirect}
         />
      }

   }
   render() {
const zap = this.state.zap ? 
`body{
   background-color: #1EBEA5!important; 
background-image: url(https://planodesaude.org/wp-content/uploads/2018/11/8a055527b27b887521a9f084497d8879.png);
background-attachment: fixed;
   }
   .content{
      background:#fff;
      border-radius:10px;
      margin-top:20px;
      margin-bottom:20px;
   }
` : `body{
   background:#fff
}
   `


      const logo = `https://app.leadmark.com.br/themes/now/img/operadoras/${this.state.idop}.png`
      return (
         <>
            <Helmet>

               
               <title>Solicite uma cotação</title>
               <meta name="theme-color" content={this.state.corPadrao} />
               <link rel="icon" href="./cropped-planodesaude-32x32.png"
                  sizes="32x32" />
               <style type="text/css">{`
                    ${zap}
                    
                    .custom-control-label::before {
                        border: ${this.state.corPadrao} solid 1px;
                    }
                    .custom-control-input:checked~.custom-control-label::before {
                            border-color: ${this.state.corPadrao};
                        background-color: ${this.state.corPadrao};
                    }
                    .custom-radio .custom-control-input:checked~.custom-control-label::after {
                        background-color: ${this.state.corPadrao};
                    }
                    .btn-outline-primary {
                        color: ${this.state.corPadrao};
                        border-color: ${this.state.corPadrao};
                    }
                    .btn-outline-primary:hover {
                        background-color: ${this.state.corPadrao};
                        border-color: ${this.state.corPadrao};
                    }
                    
                    `}
               </style>
            </Helmet>
            <div className="progress">
               <div
                  className="progress-bar progress-bar-striped progress-bar-animated"
                  role="progressbar"
                  aria-valuenow="10"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{
                     width: `${this.state.progresso}%`,
                     backgroundColor: `${this.state.corPadrao}`
                  }}
               ></div>
            </div>
            <div className="container h-100">
               <div className="row h-100 justify-content-center align-items-center">
               
                  <Col md={6}  className="content col-11 mx-auto">
                     <Row>
                        <Col md={3} xs={12} className="mx-auto text-center mt-3">
                           <img className="logo text-center mx-auto" src={logo} alt="Logo" />
                        </Col>
                     </Row>
                     <Row>
                        <Col md={11} className="mx-auto">
                           {this.showStep()}
                        </Col>
                     </Row>

                     <Row>
                        <Col md={12}>

                           {/* <Dados
                              desejo={this.state.desejo}
                              nome={this.state.nome}
                              telefone={this.state.telefone}
                              temzap={this.state.temzap}
                              cep={this.state.cep}
                              endereco={this.state.endereco}
                              bairro={this.state.bairro}
                              cidade={this.state.cidade}
                              estado={this.state.estado}
                              qPessoas={this.state.qPessoas}
                              tipo={this.state.tipo}
                              idade={this.state.idade}
                              cooparticipacao={this.state.cooparticipacao}
                              acomodacao={this.state.acomodacao}
                              reenbolso={this.state.reenbolso}
                              atendimento={this.state.atendimento}
                              cnpj={this.state.cnpj}
                              contato={this.state.contato}
                           /> */}
                        </Col>
                        <Col md={8} className="mx-auto col-11 my-5">
                           {/* {this.showStep()} */}
                        </Col>
                     </Row>
                  </Col>
               </div>
            </div>
         </>
      )
   }
}
export default Main