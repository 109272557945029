import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import InputMask from 'react-input-mask';
import { Button, ButtonToolbar } from 'react-bootstrap';
import api from '../../src/services/api'
import CircularProgress from '@material-ui/core/CircularProgress';


export class Cnpj extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: [],
            button: false,
        }
    }

    handleChangeCnpj(event) {
        this.props.handCnpj(event)
        const formData = localStorage.getItem('leadDental')
        const data = JSON.parse(formData)
        data.cpf_cnpj = event
        localStorage.setItem('leadDental', JSON.stringify(data))
    }


    async UpgradeLead() {
        const idLead = localStorage.getItem('idLead')

        const data = {
            id_origem: this.props.idorigem,
            id: parseInt(idLead),
            cpf_cnpj: this.props.cnpj
        }

        try {
            let response = await api.post('integracao/cadastro', data)
            //console.log(response);
            if (response.data.status === 'error') {
                this.setState({ error: response.data.critica })
                this.setState({ button: !this.state.button })

            }
            if (response.data.status === "success") {
                this.props.nextStep()
            }
        } catch (err) {
            console.error(err)
        }
    }

    async UpdateLeadSaudeDental() {
        const idLead = localStorage.getItem('IdLeadSaudeDental')

        const data = {
            id_origem: this.props.idorigem,
            id: parseInt(idLead),
            cpf_cnpj: this.props.cnpj
        }

        try {
            let response = await api.post('integracao/cadastro', data)
            //console.log(response);
            if (response.data.status === 'error') {
                this.setState({ error: response.data.critica })
                this.setState({ button: !this.state.button })

            }
            if (response.data.status === "success") {
                this.props.nextStep()
            }
        } catch (err) {
            console.error(err)
        }
    }

    continue = e => {
        e.preventDefault();
        if (this.props.cnpj.length === 0) {
            this.props.nextStep()
        } else {
            this.setState({ button: !this.state.button })
            //this.UpgradeLead()
            const idop = this.props.idop;
            if(idop !== '11'){
                this.setState({ error: [] })
                this.setState({ button: !this.state.button })
                this.UpgradeLead()
            }

            if(idop === '11'){
                this.setState({ error: [] })
                this.setState({ button: !this.state.button })
                this.UpdateLeadSaudeDental();
            }
        }

    };
    voltar = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    render() {
        return (
            <>
                <h5 className="text-center my-5 animated fadeIn">Poderia informar o CNPJ da sua empresa?</h5>
                <Form.Label style={{ fontSize: '10px' }}>CNPJ da sua empresa</Form.Label>
                <InputMask
                    type="tel"
                    className="form-control"
                    placeholder="Informe seu CNPJ"
                    mask="99.999.999/9999-99"
                    maskChar={null}
                    style={{ padding: '1.7rem 0.75rem' }}
                    onChange={(e) => this.handleChangeCnpj(e.target.value)}

                //onChange={this.props.handleChangeCnpj('cnpj')}
                />
                <ButtonToolbar className="mt-3">
                    <Button
                        size="lg"
                        variant="outline-default"
                        onClick={this.voltar}
                    >Voltar
                            </Button>
                    <Button
                        size="lg"
                        variant="outline-primary"
                        onClick={this.continue}
                        disabled={this.state.button}
                    >Continuar
                            </Button>
                    {this.state.button ? <CircularProgress size={24} className="my-auto mx-2" /> : null}
                </ButtonToolbar>
            </>
        );
    }
}

export default Cnpj


